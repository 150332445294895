<template>
  <a-modal
    :visible="visible"
    :title="title"
    width="800px"
    closable
    wrapClassName="user"
    :maskClosable="false"
    :bodyStyle="{ padding: '0' }"
    @ok="submit"
    @cancel="$emit('close')"
  >
    <div class="modal-content">
      <div class="m-4">
        <div class="w-full my-3">
          <div class="search">
            <a-input
              v-model.trim="searchStr"
              :placeholder="`查询员工`"
              addonBefore="搜索"
              @input="filterByName"
              class="w-full"
            />
          </div>
        </div>
        <a-table
          rowKey="uid"
          :dataSource="data"
          :columns="columns"
          :pagination="false"
          :loading="loading"
          :rowClassName="
            record => {
              return chooseUser.uid === record.uid ? 'bg-blue-200' : ''
            }
          "
          :customRow="
            (record, index) => {
              return {
                on: {
                  click: () => (chooseUser = record),
                  dblclick: () => {
                    chooseUser = record
                    submit()
                  }
                }
              }
            }
          "
        ></a-table>
      </div>
      <div class="text-right m-4">
        <a-pagination
          showQuickJumper
          :hideOnSinglePage="true"
          :current="current"
          :pageSize="pageSize"
          :total="total"
          @change="pageChange"
          @showSizeChange="showSizeChange"
        />
      </div>
    </div>
  </a-modal>
</template>

<script>
import listMix from '@/globalMix/listMix'
import { Drag, resetDrag } from '@/utils/directives/drag'
import { getUserList } from '@/api/query/queryApproveBills'
import { default as isEmpty } from 'lodash/isEmpty'
import { default as debounce } from 'lodash/debounce'
export default {
  name: 'UserModal',
  mixins: [listMix],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '选择员工'
    }
  },
  data() {
    return {
      current: 1,
      pageSize: 12,
      total: 0,
      loading: false,
      data: [],
      conditions: [],

      searchStr: '',
      chooseUser: {}, // 选择住房档案
      columns: [
        {
          title: '用户编码',
          dataIndex: 'userCode'
        },
        {
          title: '用户名称',
          dataIndex: 'username'
        },
        {
          title: '岗位',
          dataIndex: 'vnote'
        }
      ]
    }
  },

  created() {
    this.getList()
  },

  destroyed() {
    this.$nextTick(() => {
      resetDrag()
    })
  },

  watch: {
    visible(val) {
      if (val) {
        this.$nextTick(() => {
          Drag('.user')
        })
      }
    }
  },

  methods: {
    getList() {
      this.loading = true
      getUserList(
        { current: this.current, pageSize: this.pageSize },
        {},
        this.conditions
      )
        .then(res => {
          this.data = res.datas
          this.total = res.totalNum
        })
        .finally(() => (this.loading = false))
    },

    submit() {
      if (isEmpty(this.chooseUser)) {
        this.$message.error('请选择员工信息')
        return
      }

      this.$emit('choose', this.chooseUser)
      this.$emit('close')
    },

    // 过滤查询信息
    filterByName: debounce(function () {
      this.current = 1
      this.conditions = []
      this.conditions.push({
        key: 'username',
        value: this.searchStr
      })
      this.getList()
    }, 500)
  }
}
</script>

<style lang="less" scoped>
.modal-content {
  height: 560px;
  padding: 0;
  &.choose-active {
    background-color: #4587f5;
    color: #fff;
  }
}

/deep/.ant-table-thead {
  & > tr > th {
    padding: 8px 4px !important;
    font-size: 12px;
  }
}

/deep/.ant-table-tbody > tr > td {
  padding: 8px 4px !important;
  font-size: 12px;
}
</style>
