import SaleManage from '@/views/sale'
export default {
  path: 'saleManage',
  name: 'saleManage',
  component: SaleManage,
  redirect: 'sale/saleArea',
  children: [
    // 销售区域路由
    {
      // 销售区域
      path: 'salesArea',
      name: 'salesArea',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/salearea'
        ),
      meta: {
        title: '销售区域',
        menuKey: 'salesArea',
        keepAlive: true,
        routerName: 'saleArea'
      }
    },
    {
      // 销售区域详情
      path: 'salesAreaDetial',
      name: 'salesAreaDetial',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/salearea/SaleAreaDetial'
        ),
      meta: {
        title: '销售区域详情',
        menuKey: 'salesArea',
        keepAlive: true,
        routerName: 'saleArea'
      }
    },
    {
      // 新增销售区域
      path: 'addSaleArea',
      name: 'addSaleArea',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/salearea/AddSaleArea'
        ),
      meta: {
        title: '新增销售区域',
        menuKey: 'salesArea',
        keepAlive: true,
        routerName: 'saleArea'
      }
    },

    // 岗位医生路由
    {
      // 岗位医生
      path: 'postDoctor',
      name: 'postDoctor',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/postdoctor'
        ),
      meta: {
        title: '岗位医生',
        menuKey: 'postDoctor',
        keepAlive: true,
        routerName: 'PostDoctor'
      }
    },
    {
      // 岗位医生详情
      path: 'postDoctorDetial',
      name: 'postDoctorDetial',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/postdoctor/PostDoctorDetial'
        ),
      meta: {
        title: '岗位医生详情',
        menuKey: 'postDoctor',
        keepAlive: true,
        routerName: 'PostDoctor'
      }
    },

    {
      // 目标医生管理首页
      path: 'targetDoctorManage',
      name: 'targetDoctorManage',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/targetdoctor'
        ),
      meta: {
        title: '目标医生管理',
        menuKey: 'targetDoctorManage',
        keepAlive: true,
        routerName: 'TargetDoctor'
      }
    },
    {
      // 目标医生详情
      path: 'targetDoctorDetail',
      name: 'targetDoctorDetail',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/targetdoctor/TargetDoctorDetail'
        ),
      meta: {
        title: '目标医生详情',
        menuKey: 'targetDoctorManage',
        keepAlive: true,
        routerName: 'TargetDoctor'
      }
    },
    {
      // 目标医生分级设置
      path: 'doctorGradeSetting',
      name: 'doctorGradeSetting',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/targetdoctor/DoctorGradeSetting'
        ),
      meta: {
        title: '目标医生分级设置',
        menuKey: 'targetDoctorManage',
        keepAlive: true,
        routerName: 'TargetDoctor'
      }
    },
    {
      // 目标医生分级设置
      path: 'addGradeStting',
      name: 'addGradeStting',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/targetdoctor/AddGradeStting'
        ),
      meta: {
        title: '新增目标医生分级设置',
        menuKey: 'targetDoctorManage',
        keepAlive: true,
        routerName: 'TargetDoctor'
      }
    },
    {
      // 目标医生分级设置
      path: 'gradeSettingDetail',
      name: 'gradeSettingDetail',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/targetdoctor/GradeSettingDetail'
        ),
      meta: {
        title: '目标医生分级设置详情',
        menuKey: 'targetDoctorManage',
        keepAlive: true,
        routerName: 'TargetDoctor'
      }
    },

    // 拜访路由
    {
      // 拜访列表
      path: 'visit',
      name: 'visit',
      component: () =>
        import(/* webpackChunkName: "group-custmanage" */ '@/views/sale/visit'),
      meta: {
        title: '拜访',
        menuKey: 'visit',
        keepAlive: true,
        routerName: 'Visit'
      }
    },
    {
      // 拜访详情
      path: 'visitDetial',
      name: 'visitDetial',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/visit/VisitDetial'
        ),
      meta: {
        title: '拜访详情',
        menuKey: 'visit',
        keepAlive: true,
        routerName: 'Visit'
      }
    },
    {
      // 拜访补签详情
      path: 'visitRetroactDetial',
      name: 'visitRetroactDetial',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/visit/VisitRetroactDetial'
        ),
      meta: {
        title: '拜访补签详情',
        menuKey: 'visit',
        keepAlive: true,
        routerName: 'Visit'
      }
    },

    // 补签申请
    {
      path: 'buqianPcApp',
      name: 'buqianPcApp',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/visitRetroact'
        ),
      meta: { title: '补签申请', menuKey: 'buqianPcApp' }
    },
    {
      // 新增拜访补签
      path: 'addVisitRetroact',
      name: 'addVisitRetroact',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/visitRetroact/AddVisitRetroact'
        ),
      meta: { title: '新增拜访补签申请', menuKey: 'buqianPcApp' }
    },

    // 随访路由
    {
      // 随访列表
      path: 'followUpVisit',
      name: 'followUpVisit',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/followVisit'
        ),
      meta: {
        title: '随访',
        menuKey: 'followUpVisit',
        keepAlive: true,
        routerName: 'FollowVisit'
      }
    },
    {
      // 随访详情
      path: 'followVisitDetial',
      name: 'followVisitDetial',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/followVisit/FollowVisitDetial'
        ),
      meta: {
        title: '随访详情',
        menuKey: 'followUpVisit',
        keepAlive: true,
        routerName: 'FollowVisit'
      }
    },

    // 区域外时间路由
    {
      // 区域外时间列表
      path: 'outsideTime',
      name: 'outsideTime',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/areatime'
        ),
      meta: { title: '区域外时间', menuKey: 'outsideTime' }
    },
    {
      // 区域外时间详情
      path: 'areaTimeDetial',
      name: 'areaTimeDetial',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/areatime/AreaTimeDetial'
        ),
      meta: { title: '区域外时间详情', menuKey: 'outsideTime' }
    },

    // 门店系统路由
    {
      // 门店系统列表
      path: 'storeManage',
      name: 'storeManage',
      component: () =>
        import(/* webpackChunkName: "group-custmanage" */ '@/views/sale/store'),
      meta: { title: '干鲜草门店档案', menuKey: 'storeManage' }
    },
    {
      // 门店系统详情
      path: 'storeDetail',
      name: 'storeDetail',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/store/StoreDetail'
        ),
      meta: { title: '干鲜草门店档案详情', menuKey: 'storeManage' }
    },
    // 共享文档
    {
      path: 'documentManage',
      name: 'documentManage',
      component: () => import('@/views/sale/docsmanage'),
      meta: { title: '文档管理', menuKey: 'documentManage' }
    },

    // 月销量指标
    {
      // 月销量指标首页
      path: 'monthlySalesTarget',
      name: 'monthlySalesTarget',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/monthsale'
        ),
      meta: { title: '月销量指标', menuKey: 'monthlySalesTarget' }
    },
    {
      // 月销量指标详情(编辑)
      path: 'addMothSaleNum',
      name: 'addMothSaleNum',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/monthsale/AddMothSaleNum'
        ),
      meta: { title: '新增月销量指标', menuKey: 'monthlySalesTarget' }
    },
    {
      // 月销量指标详情(编辑)
      path: 'mothSaleNumDetail',
      name: 'mothSaleNumDetail',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/monthsale/MothSaleNumDetail'
        ),
      meta: { title: '月销量指标详情', menuKey: 'monthlySalesTarget' }
    },

    // 各省产品销售指标月份占比
    {
      // 各省产品销售指标月份占比首页
      path: 'indexMonthProportion',
      name: 'indexMonthProportion',
      component: () =>
        import(
          /* webpackChunkName: "indexMonthProportion" */ '@/views/sale/indexMonthProvince'
        ),
      meta: {
        title: '各省产品销售指标月份占比',
        menuKey: 'indexMonthProportion'
      }
    },

    // 目标医院销售指标
    {
      // 目标医院销售指标首页
      path: 'hospitalSaleIndex',
      name: 'hospitalSaleIndex',
      component: () =>
        import(
          /* webpackChunkName: "hospitalSaleIndex" */ '@/views/sale/hospSaleIndex'
        ),
      meta: { title: '目标医院销售指标', menuKey: 'hospitalSaleIndex' }
    },

    // 各省产品销售指标
    {
      // 月各省产品销售指标首页
      path: 'provinceIndex',
      name: 'provinceIndex',
      component: () =>
        import(
          /* webpackChunkName: "provinceIndex" */ '@/views/sale/provinceIndex'
        ),
      meta: { title: '各省产品销售指标', menuKey: 'provinceIndex' }
    },

    // 收款确认
    {
      // 收款确认
      path: 'receiveMnyConfirm',
      name: 'receiveMnyConfirm',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/receiveMny'
        ),
      meta: { title: '收款确认', menuKey: 'receiveMnyConfirm' }
    },

    {
      // 学术省总规
      path: 'provinceZG',
      name: 'provinceZG',
      component: () =>
        import(
          /* webpackChunkName: "sale-custmanage" */ '@/views/sale/provinceZG'
        ),
      meta: { title: '学术省总规', menuKey: 'provinceZG' }
    },
    {
      // 学术省总规详情
      path: 'provinceZGForm',
      name: 'provinceZGForm',
      component: () =>
        import(
          /* webpackChunkName: "sale-custmanage" */ '@/views/sale/provinceZG/ProvinceZGForm'
        ),
      meta: {
        title: '学术省总规详情',
        menuKey: 'provinceZG'
      }
    },

    {
      // 学术地总规
      path: 'localZG',
      name: 'localZG',
      component: () =>
        import(
          /* webpackChunkName: "sale-custmanage" */ '@/views/sale/localZG'
        ),
      meta: { title: '学术地总规', menuKey: 'localZG' }
    },
    {
      // 学术地总规详情
      path: 'localZGForm',
      name: 'localZGForm',
      component: () =>
        import(
          /* webpackChunkName: "sale-custmanage" */ '@/views/sale/localZG/LocalZGForm'
        ),
      meta: {
        title: '学术地总规详情',
        menuKey: 'localZG'
      }
    },

    {
      // 学术特殊备案省规
      path: 'specialZG',
      name: 'specialZG',
      component: () =>
        import(
          /* webpackChunkName: "sale-custmanage" */ '@/views/sale/specialZG'
        ),
      meta: { title: '学术特殊备案省规', menuKey: 'specialZG' }
    },
    {
      // 学术特殊备案省规详情
      path: 'specialZGForm',
      name: 'specialZGForm',
      component: () =>
        import(
          /* webpackChunkName: "sale-custmanage" */ '@/views/sale/specialZG/SpecialZGForm'
        ),
      meta: {
        title: '学术特殊备案省规详情',
        menuKey: 'specialZG'
      }
    },

    {
      // 学术数字合同
      path: 'academicChsDigitContract',
      name: 'academicChsDigitContract',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/academicChsDigitContract'
        ),
      meta: { title: '学术数字合同', menuKey: 'academicChsDigitContract' }
    },
    {
      // 学术数字合同详情
      path: 'academicCDContractForm',
      name: 'academicCDContractForm',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/academicChsDigitContract/contractForm'
        ),
      meta: { title: '学术数字合同详情', menuKey: 'academicChsDigitContract' }
    },

    {
      // 医院数量控制
      path: 'hospitalNumConfig',
      name: 'hospitalNumConfig',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/hospitalNumConfig'
        ),
      meta: { title: '医院数量控制', menuKey: 'hospitalNumConfig' }
    },

    {
      // 医院数量控制详情
      path: 'hospNumConfigForm',
      name: 'hospNumConfigForm',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/hospitalNumConfig/HospNumConfigForm'
        ),
      meta: { title: '医院数量控制详情', menuKey: 'hospitalNumConfig' }
    },

    {
      // 数字合同
      path: 'chsDigitContract',
      name: 'chsDigitContract',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/contract'
        ),
      meta: { title: '数字合同', menuKey: 'chsDigitContract' }
    },

    {
      // 数字合同详情
      path: 'contractForm',
      name: 'contractForm',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/contract/contractForm'
        ),
      meta: { title: '数字合同详情', menuKey: 'chsDigitContract' }
    },
    {
      // 业务员月盘存
      path: 'chsClerkMonthInv',
      name: 'chsClerkMonthInv',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/chsClerkMonthInv'
        ),
      meta: { title: '业务员月盘存', menuKey: 'chsClerkMonthInv' }
    },
    {
      // 业务员月盘存详情
      path: 'chsMonthForm',
      name: 'chsMonthForm',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/chsClerkMonthInv/chsMonthForm'
        ),
      meta: { title: '业务员月盘存详情', menuKey: 'chsClerkMonthInv' }
    },
    {
      // 清算盘存
      path: 'chsClearInv',
      name: 'chsClearInv',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/chsClearInv'
        ),
      meta: { title: '清算盘存', menuKey: 'chsClearInv' }
    },
    {
      // 清算盘存详情
      path: 'chsForm',
      name: 'chsForm',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/chsClearInv/chsForm'
        ),
      meta: { title: '清算盘存详情', menuKey: 'chsClearInv' }
    },
    {
      // 非学术商业返款协议
      path: 'businessRefundContract',
      name: 'businessRefundContract',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/business'
        ),
      meta: { title: '非学术商业返款协议', menuKey: 'businessRefundContract' }
    },
    {
      // 合作伙伴协议返款详情
      path: 'businessForm',
      name: 'businessForm',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/business/BusinessForm'
        ),
      meta: { title: '非学术商业返款协议', menuKey: 'businessRefundContract' }
    },
    {
      // 新零售销售费用
      path: 'partnerAgreementRefund',
      name: 'partnerAgreementRefund',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/partner'
        ),
      meta: { title: '新零售销售费用', menuKey: 'partnerAgreementRefund' }
    },
    {
      // 新零售销售费用详情
      path: 'partnerForm',
      name: 'partnerForm',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/partner/PartnerForm'
        ),
      meta: { title: '新零售销售费用详情', menuKey: 'partnerAgreementRefund' }
    },
    {
      // 非学术协议返款
      path: 'nonAcademicRefundContract',
      name: 'nonAcademicRefundContract',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/nonacademic'
        ),
      meta: { title: '非学术协议返款', menuKey: 'nonAcademicRefundContract' }
    },
    {
      // 非学术协议返款详情
      path: 'nonAcademicForm',
      name: 'nonAcademicForm',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/nonacademic/NonAcademicForm'
        ),
      meta: {
        title: '非学术协议返款详情',
        menuKey: 'nonAcademicRefundContract'
      }
    },
    {
      // 物流回执列表
      path: 'proofOfDelivery',
      name: 'proofOfDelivery',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/delivery'
        ),
      meta: {
        title: '物流回执',
        menuKey: 'proofOfDelivery'
      }
    },

    // 目标医院
    {
      path: 'targetHospApprove',
      name: 'targetHospApprove',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/targethosp'
        ),
      meta: { title: '目标医院', menuKey: 'targetHospApprove' }
    },

    // 公告消息
    {
      // 公告列表
      path: 'messageManagement',
      name: 'messageManagement',
      component: () =>
        import(/* webpackChunkName: "group-custmanage" */ '@/views/sale/anuce'),
      meta: { title: '公告消息', menuKey: 'messageManagement' }
    },

    {
      // 新增公告
      path: 'addAnuce',
      name: 'addAnuce',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/anuce/AddAnuce'
        ),
      meta: { title: '新增公告', menuKey: 'messageManagement' }
    },

    {
      // 公告详情
      path: 'anuceDetail',
      name: 'anuceDetail',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/sale/anuce/AnuceDetail'
        ),
      meta: { title: '公告详情', menuKey: 'messageManagement' }
    }
  ]
}
