export default {
  methods: {
    verityAppForm(isSubmit) {
      if (isSubmit === 'save') return true

      // 学术品种终端信息
      for (let index = 0; index < this.datas.length; index++) {
        const item = this.datas[index]

        if (!item.hospitalId) {
          this.$message.error(`第${index + 1}行，终端不能为空`)
          return false
        }

        // if (!item.productLineId) {
        //   this.$message.error(`第${index + 1}行，产品线不能为空`)
        //   return false
        // }

        if (!item.drugFormId) {
          this.$message.error(`第${index + 1}行，学术品种不能为空`)
          return false
        }

        if (!item.allowIn) {
          this.$message.error(`第${index + 1}行，是否准入院不能为空`)
          return false
        }

        if (!item.customerNum && item.customerNum != '0') {
          this.$message.error(`第${index + 1}行，客户数不能为空`)
          return false
        }

        if (!item.relation) {
          this.$message.error(`第${index + 1}行，核心人物关系不能为空`)
          return false
        }

        if (!item.extentId) {
          this.$message.error(`第${index + 1}行，终端维护程度不能为空`)
          return false
        }

        if (!item.targetNum && item.targetNum != '0') {
          this.$message.error(
            `第${index + 1}行，远期潜力目标（盒/年度）不能为空`
          )
          return false
        }

        if (!item.vnote) {
          this.$message.error(
            `第${index + 1}行，同类品种中的份额或情况表述不能为空`
          )
          return false
        }
      }

      return true
    }
  }
}
