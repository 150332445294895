<template>
  <div class="flex w-full">
    <div class="h-full flex flex-col flex-1">
      <div class="mb-4">
        <a-row :gutter="24">
          <a-col :span="8">
            <a-card
              style="border-radius: 8px"
              class="w-full"
              :bordered="false"
              :bodyStyle="{ padding: '12px' }"
            >
              <div class="card-title" style="background-color: #4587f5">
                待审批
              </div>
              <div class="text-center text-2xl font-black">
                {{ waitWorkNum }}
              </div>
              <div class="text-right text-base">任务</div>
            </a-card>
          </a-col>

          <a-col :span="8">
            <a-card
              style="border-radius: 8px"
              class="w-full"
              :bordered="false"
              :bodyStyle="{ padding: '12px' }"
            >
              <div class="card-title" style="background-color: #10b981">
                已审批
              </div>
              <div class="text-center text-2xl font-black">
                {{ hasWorkNum }}
              </div>
              <div class="text-right text-base">任务</div>
            </a-card>
          </a-col>

          <a-col :span="8">
            <a-card
              style="border-radius: 8px"
              class="w-full"
              :bordered="false"
              :bodyStyle="{ padding: '12px' }"
            >
              <div class="card-title" style="background-color: #eab308">
                已发起
              </div>
              <div class="text-center text-2xl font-black">
                {{ sendWorkNum }}
              </div>
              <div class="text-right text-base">任务</div>
            </a-card>
          </a-col>
        </a-row>
      </div>

      <div class="flex-1">
        <a-card
          :bordered="false"
          :bodyStyle="{ padding: 0, height: '640px' }"
          class="w-full rounded-lg"
        >
          <div class="flex justify-between items-center p-4">
            <span class="font-black text-base">待办列表</span>
            <span class="flex items-center">
              <a-popconfirm
                placement="bottom"
                okText="是"
                cancelText="否"
                @confirm="handldConfirm"
              >
                <template slot="title">是否取回？</template>
                <a-button v-if="chooseValue === '2'" class="mr-4">
                  取回
                </a-button>
              </a-popconfirm>

              <a-dropdown placement="bottomLeft">
                <a-button
                  class="mr-4"
                  type="primary"
                  v-show="
                    chooseValue === '1' &&
                    tabKey === 'common' &&
                    (activeKey === 'hosp' || activeKey === 'doctor')
                  "
                >
                  批量操作
                </a-button>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a href="javascript:;" @click="handleBatch('1')">
                      批量通过
                    </a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" @click="handleBatch('98')">
                      批量回退
                    </a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" @click="handleBatch('15')">
                      批量终止
                    </a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>

              <a-radio-group
                :value="chooseValue"
                @change="billTypeChange"
                button-style="solid"
              >
                <a-radio-button value="1" class="text-xs">
                  待审批
                </a-radio-button>
                <a-radio-button value="2" class="text-xs">
                  已审批
                </a-radio-button>
                <a-radio-button
                  :disabled="tabKey === 'proxy'"
                  value="3"
                  class="text-xs"
                >
                  已发起
                </a-radio-button>
              </a-radio-group>
            </span>
          </div>

          <a-tabs :activeKey="tabKey" :animated="false" @change="tabsChange">
            <a-tab-pane key="common" tab="常规审批">
              <div class="flex-1 flex flex-col justify-between">
                <a-table
                  :columns="getColumns"
                  :rowKey="record => record.pk"
                  :dataSource="billList"
                  :pagination="false"
                  :loading="loading"
                  :components="components"
                  @change="billListChange"
                  :rowSelection="
                    chooseValue === '2'
                      ? {
                          columnWidth: '24px',
                          onChange: onSelectChange,
                          type: 'radio',
                          selectedRowKeys: selectedRowKeys
                        }
                      : chooseValue === '1' &&
                        ['hosp', 'doctor'].includes(activeKey)
                      ? {
                          onChange: onSelectAPPChange,
                          selectedRowKeys: selectedRowAPPkeys
                        }
                      : null
                  "
                  :customRow="
                    record => {
                      return {
                        on: {
                          dblclick: () => {
                            openModal(record)
                          },
                          click: () => {
                            openModal(record)
                          }
                        }
                      }
                    }
                  "
                >
                  <div
                    slot="filterDropdown"
                    slot-scope="{
                      setSelectedKeys,
                      selectedKeys,
                      confirm,
                      clearFilters,
                      column
                    }"
                    class="custom-filter-dropdown"
                  >
                    <a-input
                      v-ant-ref="c => (searchInput = c)"
                      placeholder="查询待办标题"
                      :value="selectedKeys[0]"
                      ref="input"
                      @change="
                        e =>
                          setSelectedKeys(
                            e.target.value ? [e.target.value] : []
                          )
                      "
                      @pressEnter="
                        () => handleSearch(selectedKeys, confirm, column)
                      "
                      style="width: 180px; margin-bottom: 8px; display: block"
                    />
                    <a-button
                      type="primary"
                      @click="() => handleSearch(selectedKeys, confirm, column)"
                      icon="search"
                      style="width: 80px; margin-right: 12px"
                    >
                      查询
                    </a-button>
                    <a-button
                      @click="() => handleReset(clearFilters, column)"
                      style="width: 80px"
                    >
                      重置
                    </a-button>
                  </div>

                  <a-icon
                    slot="filterIcon"
                    type="search"
                    :style="{ color: searchText ? '#4587f5' : undefined }"
                  />

                  <template slot="subjectItem" slot-scope="subject, record">
                    <a @click="openModal(record)">
                      {{ subject }}
                    </a>
                  </template>
                  <template slot="dateItem" slot-scope="date">
                    <span>{{ getTime(date) }}</span>
                  </template>
                  <template slot="receiveTimeItem" slot-scope="receiveTime">
                    <span>
                      {{ receiveTime === null ? '无' : getTime(receiveTime) }}
                    </span>
                  </template>
                  <template slot="billtypeItem" slot-scope="billtype, record">
                    <span>
                      {{ getBillTypeName(billtype, record.unbindApp) }}
                    </span>
                  </template>
                  <template slot="sendnameItem" slot-scope="curname">
                    <span>
                      {{ curname || '无' }}
                    </span>
                  </template>
                  <span slot="status" slot-scope="text">
                    <a-badge
                      :status="text | statusTypeFilter"
                      :text="text | statusFilter"
                    />
                  </span>
                </a-table>

                <div class="text-right my-4 px-4">
                  <a-pagination
                    :hideOnSinglePage="true"
                    :defaultPageSize="pageNum"
                    :current="currentPage"
                    :total="total"
                    @change="onChange"
                    @showSizeChange="onShowSizeChange"
                  />
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane
              key="proxy"
              tab="特殊待办"
              :style="tabKey === 'proxy' ? { minHeight: '200px' } : {}"
            >
              <proxy-list
                ref="proxylist"
                :chooseValue="chooseValue"
                @open-modal="openModal"
              ></proxy-list>
            </a-tab-pane>
          </a-tabs>
        </a-card>
      </div>
    </div>
    <div class="ml-4">
      <a-card
        style="width: 256px"
        :bordered="false"
        :bodyStyle="{ padding: '12px 0' }"
        class="rounded-lg"
      >
        <a-menu
          mode="vertical"
          style="
            width: 100%;
            border-right: none;
            height: 750px;
            overflow-y: auto;
          "
          :selectedKeys="[activeKey]"
          @click="appChange"
        >
          <a-menu-item
            v-for="{ act, tab, type } in waitTabs"
            :disabled="tabKey === 'proxy'"
            :key="act"
          >
            <div class="flex justify-between items-center">
              <span class="pr-4">{{ tab }}</span>
              <a-badge
                :count="waitNum[type]"
                :number-style="{
                  backgroundColor: '#f87171',
                  color: '#fff'
                }"
              ></a-badge>
            </div>
          </a-menu-item>
        </a-menu>
      </a-card>
    </div>

    <detailWaitModal
      v-if="showModal"
      :waitWork="waitWork"
      :chooseValue="chooseValue"
      :tabKey="tabKey"
      @close="showModal = false"
      @refresh="refresh"
    ></detailWaitModal>
  </div>
</template>

<script>
import detailWaitModal from '@/views/workplace/detailwaitworkmodal/index'
import ProxyList from './components/ProxyList'
import store from '@/store'

const statusMap = {
  0: { status: 'success', text: '审批通过' },
  1: { status: 'default', text: '待发' },
  3: { status: 'processing', text: '待处理' },
  4: { status: 'warning', text: '处理中' },
  5: { status: 'default', text: '撤销' },
  15: { status: 'error', text: '终止' },
  98: { status: 'error', text: '回退' },
  99: { status: 'warning', text: '作废' },
  96: { status: 'processing', text: '挂起' }
}

import {
  getListWaitWork,
  getListSendWork,
  getListDoneWork,
  getCounts
} from '@/api/acdemicActive/workplace'

import {
  getWorkConfig,
  ACTIVE_COLUMNS,
  NOT_ACTIVE_COLUMNS
} from '@/configs/bill-config'

import { handleBatch } from '@/api/flow/flow'
import { getHandle } from '@/api/acdemicActive/acdemic-application'
import { getResizeableTitle } from '@/utils/table'
import moment from 'moment'
import wwebscoket from '@/globalMix/wwebscoket'

export default {
  name: 'workplace',
  components: {
    ProxyList,
    detailWaitModal
  },
  mixins: [wwebscoket],
  data() {
    this.components = getResizeableTitle(NOT_ACTIVE_COLUMNS)
    return {
      activeColumns: ACTIVE_COLUMNS,
      notActiveColumns: NOT_ACTIVE_COLUMNS,

      tabKey: 'common',
      activeKey: 'all', //待办的申请tab值
      chooseValue: '1', // 待审批、已审批和已发起的
      searchText: '', //标题查询的关键信息
      currentPage: 1,
      pageNum: 10,
      showModal: false,
      total: 0,
      loading: false,
      hasWorkNum: 0, // 已审批数
      sendWorkNum: 0, //已发起数
      waitWorkNum: 0, //待审批数
      waitWork: {}, //传递到modal的数据
      paramObj: {}, // 单据代办标题的查询条件
      typeList: [], // 单据类型的查询条件
      billList: [],
      selectedRowKeys: [], //选择取回的单据列表
      selectedRowAPPkeys: [], //选择批量操作的列表
      waitWorkNumData: [], //各个待办的数据

      waitTabs: [
        { act: 'all', tab: '全部', type: 'all' },
        { act: 'active', tab: '学术会议', type: 'XSHY' },
        { act: 'nonmeeting', tab: '非学术项目', type: 'BILL_NON_MEETING_APP' },
        { act: 'activeModify', tab: '学术推广活动申请变更', type: 'XSHYBG' },
        { act: 'hosp', tab: '医院变更', type: 'YYBG' },
        { act: 'doctor', tab: '医生变更', type: 'YSBG' },
        { act: 'speaker', tab: '讲者变更', type: 'JZBG' },
        { act: 'tgdoctor', tab: '维护次月目标医生', type: 'MBYS' },
        { act: 'store', tab: '门店', type: 'MDSQ' },
        { act: 'visit', tab: '拜访补签', type: 'BFBQ' },
        { act: 'outtime', tab: '区域外时间', type: 'QYWSJ' },
        { act: 'lcostapp', tab: '地办费用', type: 'LCOSTAPP' },
        { act: 'mbyysq', tab: '目标医院', type: 'MBYYSQ' },
        { act: 'contract', tab: '数字合同', type: 'DIGIT_CONTRACT' },
        { act: 'clerk', tab: '业务员月盘存', type: 'CLERK_MONTH_INV' },
        { act: 'clear', tab: '清算盘存', type: 'CLEAR_INV' },
        {
          act: 'business',
          tab: '非学术商业返款协议',
          type: 'BILL_BUSINESS_REFUND_CONTRACT'
        },
        {
          act: 'partner',
          tab: '新零售销售费用',
          type: 'PARTNER_AGREE_REFUND'
        },
        {
          act: 'nonacademic',
          tab: '非学术返款协议',
          type: 'NON_ACADEMIC_REFUND'
        },
        {
          act: 'outercomp',
          tab: '外部公司发货单',
          type: 'BILL_OUTER_COMP_BILL'
        },
        {
          act: 'digit',
          tab: '仿制药数字合同',
          type: 'BILL_GENERIC_DRUGS_DIGIT_CONTR'
        },
        {
          act: 'rent',
          tab: '药连锁办事处用房租赁申请',
          type: 'ZFSQ'
        },
        {
          act: 'fcbg',
          tab: '药连锁房产续租/变更申请',
          type: 'FCBG'
        },
        {
          act: 'register',
          tab: '药连锁自有房产登记申请',
          type: 'ZFDJ'
        },
        {
          act: 'dormitory',
          tab: '药连锁公司驻地宿舍申请',
          type: 'YGSSSQ'
        },
        {
          act: 'zfdg',
          tab: '药连锁房产对公付款单',
          type: 'ZFDG'
        },
        {
          act: 'zfds',
          tab: '药连锁房产对私报销单',
          type: 'ZFDS'
        },
        {
          act: 'hcvapp',
          tab: '丙肝公费送检申请单',
          type: 'GFSJ'
        },
        {
          act: 'hcvreim',
          tab: '丙肝公费送检核销单',
          type: 'GFHX'
        },
        {
          act: 'grid',
          tab: '学术终端品种网格化申请单',
          type: 'WGSQ'
        },
        {
          act: 'chsDigitContratct',
          tab: '学术数字合同',
          type: 'CHS_DIGIT_CONTRACT'
        }
      ]
    }
  },

  computed: {
    getColumns() {
      if (this.activeKey === 'active') return ACTIVE_COLUMNS

      return NOT_ACTIVE_COLUMNS
    },

    waitNum() {
      // 学术会议单据类型（需要累加）
      const XshyType = ['XSSQ', 'XSBX', 'YFKD', 'DSFK', 'DGFK', 'JDSQ']
      const waitNumMap = {}
      this.waitWorkNumData.forEach(item => {
        if (XshyType.includes(item.billType)) {
          waitNumMap['XSHY'] = (waitNumMap['XSHY'] || 0) + item.num
        } else {
          waitNumMap[item.billType] = item.num
        }
      })
      return waitNumMap
    }
  },

  created() {
    this.typeList = this.getWorkConfig(this.activeKey)
    this.getListWaitWork()
  },

  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].status
    }
  },

  methods: {
    getWorkConfig,

    getBillTypeName(type, isUnbind) {
      if (type === 'XSSQ') return '学术推广活动申请单'
      if (type === 'XSBX') return '学术推广活动总结单'
      if (type === 'BILL_NON_MEETING_APP') return '非会议类学术项目申请单'
      if (type === 'DGFK') return '对公付款单'
      if (type === 'DSFK') return '对私付款单'
      if (type === 'YFKD') return '预付款单'
      if (type === 'JDSQ') return '接待申请单'
      if (type === 'YYBG') return '医院变更申请'
      if (type === 'YSBG') return '医生变更申请'
      if (type === 'JZBG') return '讲者变更申请'
      if (type === 'MDSQ') return '门店申请'
      if (type === 'BFBQ') return '拜访补签'
      if (type === 'LCOSTAPP') return '省办费用申请'
      if (type === 'MBYS' && isUnbind) return '解绑申请'
      if (type === 'MBYS' && !isUnbind) return '常规申请'
      if (type === 'QYWSJ') return '区域外时间申请'
      if (type === 'DIGIT_CONTRACT') return '数字合同申请'
      if (type === 'CLERK_MONTH_INV') return '业务员月盘存补签'
      if (type === 'CLEAR_INV') return '清算盘存申请'
      if (type === 'MBYYSQ') return '目标医院申请'
      if (type === 'PARTNER_AGREE_REFUND') return '新零售销售费用'
      if (type === 'NON_ACADEMIC_REFUND') return '非学术返款协议'
      if (type === 'BILL_NON_MEETING_APP') return '非学术项目申请'
      if (type === 'BILL_BUSINESS_REFUND_CONTRACT') return '非学术商业返款协议'
      if (type === 'BILL_OUTER_COMP_BILL') return '外部公司发货单申请'
      if (type === 'BILL_GENERIC_DRUGS_DIGIT_CONTR') return '仿制药数字合同申请'
      if (type === 'ZFSQ') return '药连锁办事处用房租赁申请'
      if (type === 'ZFDJ') return '药连锁自有房产登记申请'
      if (type === 'YGSSSQ') return '药连锁公司驻地宿舍申请'
      if (type === 'ZFDG') return '药连锁房产对公付款单申请'
      if (type === 'ZFDS') return '药连锁房产对私报销单申请'
      if (type === 'FCBG') return '药连锁房产续租/变更申请'
      if (type === 'XSHYBG') return '学术推广活动申请变更单'
      if (type === 'GFSJ') return '丙肝公费送检申请单'
      if (type === 'GFHX') return '丙肝公费送检核销单'
      if (type === 'WGSQ') return '学术终端品种网格化申请单'
      if (type === 'CHS_DIGIT_CONTRACT') return '学术数字合同'
    },
    /**
     * 转换时间
     * @param {String} date //时间戳
     */

    getTime(date) {
      return moment(parseInt(date)).format('YYYY-MM-DD HH:mm:ss')
    },

    // 改变待办类型
    billTypeChange(e) {
      this.chooseValue = e.target.value
      this.clearData()
      this.billListChange()
    },

    tabsChange(tabKey) {
      if (tabKey === 'proxy' && this.chooseValue === '3') {
        this.$message.warn('请选择"待审批" 或者 "已审批"的查询条件')
        return
      }

      this.tabKey = tabKey
    },

    /**
     * 改变当前页触发
     * @param {Integer} currentPage 跳转页
     */
    onChange(currentPage) {
      this.currentPage = currentPage
      this.billListChange()
    },

    /**
     * 监听每页条数变化时触发
     * @param {Integer} currentPage
     * @param {Integer} pageNum
     */
    onShowSizeChange(currentPage, pageNum) {
      this.currentPage = currentPage
      this.pageNum = pageNum
      this.billListChange()
    },

    /**
     * 获取控制台的单据数
     */
    getCounts() {
      getCounts().then(response => {
        const { hasWorkNum, sendWorkNum, waitWorkNum } = response
        this.hasWorkNum = hasWorkNum
        this.sendWorkNum = sendWorkNum
        this.waitWorkNum = waitWorkNum
      })
    },

    /**
     * 获取待办列表
     * @param {Integer} currentPage 当前页
     * @param {Integer} pageNum 当前页的条数
     */
    getListWaitWork() {
      this.loading = true
      getListWaitWork(
        this.currentPage,
        this.pageNum,
        this.paramObj,
        this.typeList
      )
        .then(response => {
          this.billList = response.datas
          this.total = response.totalNum
          this.getCounts()
        })
        .finally(() => (this.loading = false))
    },
    /**
     * 获取已发起列表
     * @param {Integer} currentPage 当前页
     * @param {Integer} pageNum 当前页的条数
     */
    getListSendWork() {
      this.loading = true
      getListSendWork(
        this.currentPage,
        this.pageNum,
        this.paramObj,
        this.typeList
      )
        .then(response => {
          this.billList = response.datas
          this.total = response.totalNum
          this.getCounts()
        })
        .finally(() => (this.loading = false))
    },
    /**
     * 获取已审批列表
     * @param {Integer} currentPage 当前页
     * @param {Integer} pageNum 当前页的条数
     */
    getListDoneWork() {
      this.loading = true
      getListDoneWork(
        this.currentPage,
        this.pageNum,
        this.paramObj,
        this.typeList
      )
        .then(response => {
          this.billList = response.datas
          this.total = response.totalNum
          this.getCounts()
        })
        .finally(() => (this.loading = false))
    },

    openModal(item) {
      this.showModal = true
      this.waitWork = item
    },

    refresh() {
      this.clearData()
      this.billListChange()
      this.$refs.proxyList.getList()
    },

    /**
     * tab的key值
     */
    appChange(target) {
      this.activeKey = target.key
      this.typeList = this.getWorkConfig(this.activeKey)
      this.clearData()
      this.billListChange()
    },

    // 每次更新状态时都清除数据
    clearData() {
      if (this.$refs.input) {
        this.$refs.input.stateValue = ''
      }

      this.currentPage = 1
      this.nextPage = true
      this.searchText = ''
      this.paramObj = {}
      this.billList = []
      this.selectedRowAPPkeys = []
      this.selectedRowKeys = []
    },

    billListChange(pagination, filters) {
      if (pagination) this.currentPage = 1
      if (filters) {
        const { billtype } = filters
        if (billtype && billtype.length) {
          this.typeList = billtype
        } else {
          this.typeList = this.getWorkConfig(this.activeKey)
        }
      }

      if (this.chooseValue === '1') this.getListWaitWork()
      if (this.chooseValue === '2') this.getListDoneWork()
      if (this.chooseValue === '3') this.getListSendWork()
    },

    storeGetters(str) {
      return store.getters[str]
    },

    // 标题关键字搜索
    handleSearch(selectedKeys, confirm) {
      if (selectedKeys[0]) {
        this.paramObj = { content: selectedKeys[0] }
      } else {
        this.paramObj = {}
      }

      confirm()
      this.searchText = selectedKeys[0]
    },

    // 重置标题关键字搜索
    handleReset(clearFilters) {
      this.paramObj = {}
      clearFilters()
      this.searchText = ''
    },

    // 取回的单据
    onSelectChange(value) {
      this.selectedRowKeys = value
    },

    onSelectAPPChange(value) {
      this.selectedRowAPPkeys = value
    },

    // 取回单据
    handldConfirm() {
      if (this.tabKey === 'common') {
        if (this.selectedRowKeys && this.selectedRowKeys.length) {
          const billId = this.selectedRowKeys[0]
          const target = this.billList.find(item => billId === item.pk)
          if (target.state === '0')
            return this.$message.warning('单据已审批通过,无法取回')
          else {
            target.optype = '7'
            getHandle(target).then(() => {
              this.chooseValue = '1'
              this.$message.success('取回成功')
              this.refresh()
            })
          }
        } else {
          this.$message.warning('请选择要取回的单据')
        }
      } else {
        // 代理审批的取回
        this.$refs.proxylist.handldConfirm()
      }
    },

    // 批量操作
    handleBatch(status) {
      if (this.selectedRowAPPkeys && this.selectedRowAPPkeys.length) {
        const billList = []
        for (let id of this.selectedRowAPPkeys) {
          const target = this.billList.find(item => id === item.pk)
          if (!target) continue

          target.optype = status
          billList.push(target)
        }

        handleBatch(billList).then(() => {
          this.refresh()
          if (status === '1') {
            this.$message.success('批量审批成功')
          } else if (status === '98') {
            this.$message.success('批量回退成功')
          } else {
            this.$message.success('批量终止成功')
          }
        })
      } else {
        this.$message.warning('请选择要批量操作的单据')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.card-title {
  border-radius: 9999px;
  padding: 6px 24px;
  display: inline-block;
  font-size: 14px;
  font-weight: black;
  color: white;
}

/deep/ .ant-table-thead {
  & > tr > th {
    font-size: 13px;
    font-weight: black;
    color: rgba(153, 153, 153, 1);
  }
}

/deep/ .ant-table-thead > tr > th {
  padding: 10px 12px !important;
  &.ant-table-selection-column {
    padding: 6px 0px 6px 16px !important;
  }
}

/deep/.ant-table-tbody > tr > td {
  padding: 10px 12px !important;
  &.ant-table-selection-column {
    padding: 6px 0px 6px 16px !important;
  }
}
/deep/ .ant-dropdown-menu-item {
  width: 120px;
  padding: 8px 16px;
}
</style>
