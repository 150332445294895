import request from '@/utils/request'
import { validateExcelDoc, downLoadFile } from '@/utils/exportExcel'

/**
 * 学术终端网格化申请单列表
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 */
export function getGridAppList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `gridApp/getGridAppList/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 更新学术终端网格化申请单
 * @param {Object} data
 * @returns
 */
export function updateGridApp(data) {
  return request({
    method: 'post',
    url: `gridApp/updateGridApp`,
    data
  })
}

/**
 * 保存学术终端网格化申请单
 * @param {Object} data
 * @returns
 */
export function saveGridApp(data) {
  return request({
    method: 'post',
    url: `gridApp/saveGridApp`,
    data
  })
}

/**
 * 提交学术终端网格化申请单
 * @param {Object} data
 * @returns
 */
export function submitGridApp(data) {
  return request({
    method: 'post',
    url: `oaflow/submitGridApp`,
    data
  })
}

/**
 * 保存学术终端网格化申请单
 * @param {String} id
 * @returns
 */
export function delGridApp(id) {
  return request({
    method: 'post',
    url: `gridApp/delGridApp/${id}`
  })
}

/**
 * 获取学术终端网格化申请单
 * @param {String} id
 * @returns
 */
export function getGridApp(id) {
  return request({
    method: 'get',
    url: `gridApp/${id}`
  })
}

/**
 * 导出学术终端网格化申请单
 * @param {Array} data 导出的条件
 */
export function getExportExcel(data = []) {
  return request({
    url: `/gridApp/exportData`,
    method: 'post',
    data: data,
    responseType: 'arraybuffer'
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    downLoadFile(data, 'csv', `学术终端网格化申请单`)
  })
}
