<template>
  <div class="m-4">
    <a-row
      v-if="!$route.query.todo && type !== 'waitwork'"
      align="middle"
      class="text-right"
    >
      <a-button
        type="primary"
        :loading="dataLoading"
        :disabled="isFlow"
        @click="save"
      >
        保存
      </a-button>
      <a-button
        type="primary"
        :loading="dataLoading"
        class="ml-2"
        :disabled="isFlow"
        @click="submit"
      >
        提交
      </a-button>
      <a-button
        type="danger"
        class="ml-2"
        :disabled="isFlow || !form.id"
        :loading="dataLoading"
        @click="del"
      >
        删除
      </a-button>
      <a-button
        class="ml-2"
        :loading="dataLoading"
        :disabled="backDisabled"
        @click="getBack"
      >
        撤销
      </a-button>
      <a-divider type="vertical"></a-divider>
      <a-button
        :loading="dataLoading"
        @click="$router.push({ name: 'academicNetworkApp' })"
      >
        返回
      </a-button>
    </a-row>

    <base-form>
      <base-form-title title="申请人信息" icon="user" />
      <base-form-items
        :formData="form"
        :formBaseList="formBaseList"
      ></base-form-items>

      <base-form-title title="学术品种终端信息" icon="message" />
      <div class="ml-4 mt-4 text-right py-1 leading-10">
        <a-button
          v-if="!$route.query.todo && !isFlow"
          class="button"
          type="primary"
          :disabled="isFlow"
          @click="addRow"
        >
          新增行
        </a-button>
        <a-divider type="vertical" v-if="!$route.query.todo && !isFlow" />
        <a-button
          v-if="!$route.query.todo && !isFlow"
          class="button"
          type="danger"
          :disabled="isFlow"
          @click="delRow"
        >
          删除行
        </a-button>
      </div>
      <a-table
        rowKey="bid"
        bordered
        :dataSource="datas"
        :columns="columns"
        :pagination="false"
        :scroll="{ x: 3200 }"
        :rowClassName="
          record => {
            return chooseRow.bid === record.bid ? 'bg-blue-100' : ''
          }
        "
        :customRow="
          (record, index) => {
            return {
              on: {
                click: () => {
                  chooseRow = record
                  chooseIndex = index
                }
              }
            }
          }
        "
      >
        <!-- title -->
        <template slot="hospTitle">
          <span>
            终端编码
            <sup class="text-red-500">*</sup>
          </span>
        </template>
        <template slot="productTitle">
          <span>
            产品线
            <sup class="text-red-500">*</sup>
          </span>
        </template>
        <template slot="drugFormTitle">
          <span>
            学术品种
            <sup class="text-red-500">*</sup>
          </span>
        </template>
        <template slot="allowInTitle">
          <span>
            是否准入医院
            <sup class="text-red-500">*</sup>
          </span>
        </template>
        <template slot="customerNumTitle">
          <span>
            客户数
            <sup class="text-red-500">*</sup>
          </span>
        </template>
        <template slot="relationTitle">
          <span>
            核心人物关系
            <sup class="text-red-500">*</sup>
          </span>
        </template>
        <template slot="extentTitle">
          <span>
            终端维护程度
            <sup class="text-red-500">*</sup>
          </span>
        </template>
        <template slot="targetNumTitle">
          <span>
            远期潜力目标（盒/年度）
            <sup class="text-red-500">*</sup>
          </span>
        </template>
        <template slot="vnoteTitle">
          <span>
            同类品种中的份额或情况表述
            <sup class="text-red-500">*</sup>
          </span>
        </template>

        <template slot="index" slot-scope="text, record, index">
          <span>{{ index + 1 }}</span>
        </template>
        <template slot="hosp" slot-scope="text, record">
          <span v-if="isFlow">{{ text }}</span>
          <a-input-search
            v-else
            v-model="record.hospitalCode"
            :disabled="isFlow"
            :ref="`hospitalCode${record.bid}`"
            @focus="focusTable(`hospitalCode`, record, 'hospitalCode')"
            @search="onSearch('hospitalCode')"
            @change="onClear('hospitalCode', record)"
          ></a-input-search>
        </template>
        <template slot="drugForm" slot-scope="text, record">
          <span v-if="isFlow">{{ text }}</span>
          <a-input-search
            v-else
            v-model="record.drugFormCode"
            :disabled="isFlow"
            :ref="`drugFormCode${record.bid}`"
            @focus="focusTable(`drugFormCode`, record, 'drugFormCode')"
            @search="onSearch('drugFormCode')"
            @change="onClear('drugFormCode', record)"
          ></a-input-search>
        </template>
        <!-- 产品线 -->
        <template slot="product" slot-scope="text, record">
          <span v-if="isFlow">{{ record.productLineName }}</span>
          <a-select
            v-else
            v-model="record.productLineId"
            :disabled="isFlow"
            @change="onChangeProductLine(record)"
          >
            <a-select-option
              v-for="item in productLineList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.text }}
            </a-select-option>
          </a-select>
        </template>
        <!-- 是否准入医院 -->
        <template slot="allowIn" slot-scope="text, record">
          <span v-if="isFlow">{{ text }}</span>
          <a-select v-else v-model="record.allowIn" :disabled="isFlow">
            <a-select-option value="是">是</a-select-option>
            <a-select-option value="否">否</a-select-option>
          </a-select>
        </template>
        <!-- 客户数 -->
        <template slot="customerNum" slot-scope="text, record">
          <span v-if="isFlow">{{ text }}</span>
          <a-input
            v-else
            v-model="record.customerNum"
            :disabled="isFlow"
            :oninput="oninput.number"
          ></a-input>
        </template>
        <!-- 核心人物关系 -->
        <template slot="relation" slot-scope="text, record">
          <span v-if="isFlow">{{ text }}</span>
          <a-select v-else v-model="record.relation" :disabled="isFlow">
            <a-select-option value="良好">良好</a-select-option>
            <a-select-option value="一般">一般</a-select-option>
          </a-select>
        </template>
        <!-- 终端维护程度 -->
        <template slot="extent" slot-scope="text, record">
          <span v-if="isFlow">{{ record.extentName }}</span>
          <a-select
            v-else
            v-model="record.extentId"
            :disabled="isFlow"
            @change="onChangeExtent(record)"
          >
            <a-select-option
              v-for="item in extentList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.text }}
            </a-select-option>
          </a-select>
        </template>
        <!-- 远期潜力目标（盒/年度） -->
        <template slot="targetNum" slot-scope="text, record">
          <span v-if="isFlow">{{ text }}</span>
          <a-input
            v-else
            v-model="record.targetNum"
            :disabled="isFlow"
            :oninput="oninput.number"
          ></a-input>
        </template>
        <!-- 同类品种中的份额或情况表述 -->
        <template slot="vnote" slot-scope="text, record">
          <span v-if="isFlow">{{ text }}</span>
          <a-textarea
            v-else
            v-model="record.vnote"
            :disabled="isFlow"
          ></a-textarea>
        </template>
      </a-table>

      <!-- 流程信息 -->
      <process
        v-if="form.flowInfoDO"
        :status="isFlow"
        :summaryid="form.flowInfoDO.summaryid"
        :nextapproveman="form.flowInfoDO.nextapproveman"
        :curApprover="form.flowInfoDO.curApprover"
      />
      <!-- 审批意见 -->
      <comment :uid="form.applicant" :billId="form.id"></comment>
    </base-form>

    <!-- 品种 -->
    <product-modal
      :visible="showProdLineVisible"
      :isConversionRate="true"
      @close="showProdLineVisible = false"
      @choose="onChooseProdLine"
    ></product-modal>

    <!-- 终端(医院管理) -->
    <hosp-modal
      :visible="showHospVisible"
      @choose="onChooseHosp"
      @close="showHospVisible = false"
    ></hosp-modal>
  </div>
</template>

<script>
import BaseForm from '@/components/FieldSet/BaseForm'
import BaseFormTitle from '@/components/FieldSet/BaseFormTitle'
import BaseFormItems from '@/components/FieldSet/BaseFormItems'
import Process from '@/components/flow/Process'
import Comment from '@/components/flow/Comment'
import ProductModal from '@/views/outer/flowBill/components/ProductModal'
import HospModal from '@/views/outer/flowBill/components/HospModal'

// api
import moment from 'moment'
import { default as cloneDeep } from 'lodash/cloneDeep'
import { isDisabled } from '@/utils/util'
import { randomKey } from '@/utils/util'
import { default as isEmpty } from 'lodash/isEmpty'
import { getDoc } from '@/configs/doc-config'
import { oninput } from '@/utils/validate'
import {
  saveGridApp,
  updateGridApp,
  delGridApp,
  getGridApp,
  submitGridApp
} from '@/api/acdemicActive/acdemic-net-application'
import { getOACancel2 } from '@/api/acdemicActive/acdemic-application'

// mixin
import verityMixin from './mixins/verity-mixin'

const templateData = {
  bid: '', // pk
  hospitalId: '', // 终端医院id
  hospitalCode: '', // 终端医院编码
  hospitalName: '', // 终端医院名称
  ncCode: '', // 终端NC编码
  pkChnlmemtypeStd: '', // 终端医院类型
  chnlmemtypeStdName: '', // 终端医院类型名称
  region: '', // 大区id
  regionName: '', // 大区名称
  province: '', // 省办id
  provinceName: '', // 省办名称
  locality: '', // 地办id
  localityName: '', // 地办名称
  county: '', // 标准区域
  productLineId: '', // 产品线id
  productLineName: '', // 产品线名称
  drugFormId: '', // 品种id
  drugFormCode: '', // 品种编码
  drugFormName: '', // 品种名称
  drugFormSpec: '', // 品种规格
  allowIn: '', // 是否准入医院
  customerNum: '', // 客户数
  relation: '', // 关系人
  extentId: '', // 终端维护程度主键
  extentName: '', // 终端维护程度名称
  targetNum: '', // 远期潜力目标（盒/年度）
  vnote: '' // 同类品种中的份额或情况表述
}

export default {
  name: 'NetAppForm',
  props: {
    type: {
      type: String,
      default: ''
    },
    pid: {
      type: String,
      default: ''
    }
  },
  components: {
    BaseForm,
    BaseFormTitle,
    BaseFormItems,
    Process,
    Comment,
    ProductModal,
    HospModal
  },
  mixins: [verityMixin],
  data() {
    return {
      curType: this.type || this.$route.query.type || 'add',
      curPid: this.pid || this.$route.query.pid,
      showHospVisible: false,
      showProdLineVisible: false,
      dataLoading: false, // 提交数据

      chooseIndex: -1, // 选择的索引
      chooseRow: {}, // 插入行和删除行

      productLineList: [], // 产品线
      extentList: [], // 终端维护程度

      datas: [],
      oninput,

      /* eslint-disable prettier/prettier */
      form: {
        applicationCode: '', // 申请编号
        appdate: '', // 申请日期
        creator: '', // 制单人
        creatorName: '', // 制单人姓名
        creationtime: '', // 制单时间
        modifier: '', // 修改人
        modifierName: '', // 修改人姓名
        modifiedtime: '', // 修改时间

        bodys: [], 

        flowStatus: '', // 流程状态
        flowInfoDO: { status: '1' } // 流程信息
      },

      formBaseList: [
        { label: '单据号', prop: 'applicationCode', disabled: true },
        { label: '申请日期', prop: 'appdate', disabled: true },
        { label: '单据状态', prop: 'flowStatus', disabled: true, type: 'status' },
        { label: '创建人', prop: 'creatorName', disabled: true },
        { label: '创建时间', prop: 'creationtime', disabled: true },
        { label: '修改人', prop: 'modifierName', disabled: true },
        { label: '修改时间', prop: 'modifytime', disabled: true },
      ],

      columns: [
        { dataIndex: 'index', title: '行号', width: 60, scopedSlots: { customRender: 'index' } },
        // 医院编码
        { dataIndex: 'hospitalCode', width: 150, slots: { title: 'hospTitle' }, scopedSlots: { customRender: 'hosp' } },
        { dataIndex: 'ncCode', title: 'NC编码', width: 150 },
        { dataIndex: 'hospitalName', title: '终端名称', width: 150, ellipsis: true },
        { dataIndex: 'chnlmemtypeStdName', title: '终端类型', width: 150 },
        { dataIndex: 'regionName', title: '大区', width: 100 },
        { dataIndex: 'provinceName', title: '省办', width: 100 },
        { dataIndex: 'localityName', title: '地办', width: 100 },
        { dataIndex: 'county', title: '区域', width: 100 },
        // // 产品线
        // { dataIndex: 'productLineName', width: 150, slots: { title: 'productTitle' }, scopedSlots: { customRender: 'product' } },
        // 品种编码
        { dataIndex: 'drugFormCode', width: 150, slots: { title: 'drugFormTitle' }, scopedSlots: { customRender: 'drugForm' } },
        { dataIndex: 'drugFormName', title: '学术品种', width: 150 },
        { dataIndex: 'drugFormSpec', title: '规格', width: 150 },
        // 是否准入院
        { dataIndex: 'allowIn', width: 150, slots: { title: 'allowInTitle' }, scopedSlots: { customRender: 'allowIn' } },
        // 客户数
        { dataIndex: 'customerNum', width: 150, slots: { title: 'customerNumTitle' }, scopedSlots: { customRender: 'customerNum' } },
        // 核心人物关系
        { dataIndex: 'relation', width: 120, slots: { title: 'relationTitle' }, scopedSlots: { customRender: 'relation' } },
        // 终端维护程度
        { dataIndex: 'extentId', width: 150, slots: { title: 'extentTitle' }, scopedSlots: { customRender: 'extent' } },
        // 远期潜力目标（盒/年度）
        { dataIndex: 'targetNum', width: 200, slots: { title: 'targetNumTitle' }, scopedSlots: { customRender: 'targetNum' } },
        // 同类品种中的份额或情况表述
        { dataIndex: 'vnote', slots: { title: 'vnoteTitle' }, scopedSlots: { customRender: 'vnote' } }
      ]
    }
  },

  computed: {
    // 是否在流程中(审批终止和审批结束也算在流程中)
    isFlow() {
      const status = this.form.flowInfoDO ? this.form.flowInfoDO.status : '1'
      return isDisabled(status)
    },

    // 允许回退
    backDisabled() {
      const status = this.form.flowInfoDO ? this.form.flowInfoDO.status : '1'
      const canBackStatus = ['0', '1', '5', '15', '98', '99']
      return canBackStatus.includes(status)
    },

    getCommonDisabled() {
      return this.isFlow || false
    }
  },

  created() {
    // 所属产品线
    getDoc('026', res => (this.productLineList = res))
    // 终端维护程度
    getDoc('071', res => (this.extentList = res))

    if(this.curType !== 'add') {
      getGridApp(this.curPid).then(res => {
        this.form = res
        this.datas = res.bodys || []
        // 单据状态(向上通知)
        const status = this.form.flowInfoDO ? this.form.flowInfoDO.status : '1'
        this.$emit('status', status)
      })
    } else {
      this.form.appdate = this.$date().format('YYYY-MM-DD')
    }
  },

  methods: {
    moment,

    focusTable(type, record, searchType, isClear = false){
      this.chooseRow = record
      if(!record[type]) {
        this.$refs[`${type}${record.bid}`].blur()

        if(!isClear) this.onSearch(searchType)
      }
    },

    onSearch(type){
      switch (type) {
        // 终端
        case 'hospitalCode':
          this.showHospVisible = true
          break;
        // 品种
        case 'drugFormCode':
          this.showProdLineVisible = true
          break;
        default:
          break;
      }
    },

    onClear(type, record = {}){
      switch (type) {
        case 'hospitalCode':
          if(!record.terminalCode){
            record.hospitalId = '' // 终端pk
            record.hospitalCode = '' // 终端编码
            record.hospitalName = '' // 终端名称
            record.ncCode	 = '' // NC编码
            record.pkChnlmemtypeStd = '' // 医院类型id
            record.chnlmemtypeStdName = '' // 医院类型名称
            record.region = '' // 所属大区id
            record.regionName = '' // 所属大区名称
            record.province = '' // 省份id
            record.provinceName = '' // 省份名称
            record.locality = '' // 所属地办
            record.localityName = '' // 所属地办名称
            record.county = '' // 标准区域
            this.focusTable('hospitalCode', record, type, true)
          }
          break;
        case 'drugFormCode':
          if(!record.drugFormCode){
            record.drugFormId = '' // 品种pk
            record.drugFormCode = '' // 品种编码
            record.drugFormName = '' // 品种名称
            record.drugFormSpec	= '' // 规格
            this.focusTable('drugFormCode', record, type, true)
          }
          break;
        default:
          break;
      }
    },

    removeBodyPk(array){
      const rex = /^bill-/
      array.forEach(i => {
        if(rex.test(i.bid)){
          i.bid = ''
        }
      });
    },

    onChangeExtent(record) {
      record.extentName = this.extentList.find(i => i.value === record.extentId).text
    },

    onChangeProductLine(record) {
      record.productLineName = this.productLineList.find(i => i.value === record.productLineId).text
    },

    // 底部新增行
    addRow(){
      const data = cloneDeep(templateData)
      data.bid = `bill-${randomKey(10)}`
      this.datas.push(data)
    },

    // 删除行
    delRow(){ 
      if(this.chooseIndex < 0 || isEmpty(this.chooseRow)) return

      this.datas.splice(this.chooseIndex, 1)
      this.chooseIndex = -1
      this.chooseRow = {}
    },

    // body-每行品种
    onChooseProdLine({ id, code, name, packagingSpecification, managerDO }){
      this.chooseRow.drugFormId = id // 品种pk
      this.chooseRow.drugFormCode = code // 品种编码
      this.chooseRow.drugFormName = name // 品种名称
      this.chooseRow.drugFormSpec = packagingSpecification // 规格
      this.chooseRow.managerCode = managerDO ? managerDO.userCode : ''
      this.chooseRow.managerName = managerDO ? managerDO.username : ''
    },

    // body-每行终端
    onChooseHosp({ 
      id,
      hospitalName,
      hospitalCode,
      ncCode,
      pkChnlmemtypeStd,
      chnlmemtypeStdName,
      areaPk,
      areaName,
      provPk,
      provName,
      pkChnlarea,
      chnlareaName,
      county
    }){
      this.chooseRow.hospitalId = id // 终端pk
      this.chooseRow.ncCode = ncCode // NC编码
      this.chooseRow.hospitalCode = hospitalCode // 终端编码
      this.chooseRow.hospitalName = hospitalName // 终端名称
      this.chooseRow.pkChnlmemtypeStd = pkChnlmemtypeStd // 终端类型
      this.chooseRow.chnlmemtypeStdName = chnlmemtypeStdName // 医院类型名称
      this.chooseRow.region = areaPk // 所属大区id
      this.chooseRow.regionName = areaName // 所属大区名称
      this.chooseRow.province = provPk // 省份id
      this.chooseRow.provinceName = provName // 省份名称
      this.chooseRow.locality = pkChnlarea // 所属地办
      this.chooseRow.localityName = chnlareaName // 所属地办名称
      this.chooseRow.county = county // 标准区域
    },

    filterData() {
      this.form.bodys = cloneDeep(this.datas)
      this.removeBodyPk(this.form.bodys)
      const submitData = cloneDeep(this.form)
      return submitData
    },

    //保存
    save(callback) {
      this.saveForm(callback)
    },

    saveForm() {
      const saveApi = this.form.id ? updateGridApp : saveGridApp

      const submitData = this.filterData()

      if (this.verityAppForm(this.form.id ? false : 'save')) {
        this.dataLoading = true
        saveApi(submitData)
          .then(() => {
            this.$message.success('保存成功')
            this.$router.replace({ name: 'academicNetworkApp' })
          }).finally(() => {
            this.dataLoading = false
          })
      } 
    },

    //提交
    submit() {
      const submitData = this.filterData()

      if (this.verityAppForm(true)) {
        this.dataLoading = true
        submitGridApp(submitData).then(() => {
          this.$message.success('提交成功')
          this.$router.replace({ name: 'academicNetworkApp' })
        }).finally(() => {
            this.dataLoading = false
          })
      }
    },

    //删除
    del() {
      this.$confirm({
        title: '是否要删除该药连锁公司驻地宿舍申请单？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          delGridApp(this.form.id).then(() => {
            this.$message.success('删除成功')
            this.$router.replace({ name: 'academicNetworkApp' })
          })
        },
        onCancel: () => {}
      })
    },

    getBack() {
      this.$confirm({
        title: '是否要撤销该学术品种终端网格化申请？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          getOACancel2(
            this.form.id,
            'WGSQ',
            this.form.flowInfoDO.summaryid
          ).then(() => {
            this.$message.success('撤销成功')
            this.form.flowInfoDO.status = '5'
            this.$emit('status', '5')
          })
        },
        onCancel: () => {}
      })
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  padding: 0 16px;
  font-size: 16px;
  font-weight: bold;
}
</style>
