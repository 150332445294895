import { cloneDeep } from 'lodash'

const BILL_VARIETY = [
  {
    type: [],
    variety: '全部',
    work: 'all'
  },
  {
    type: ['XSSQ', 'XSBX', 'YFKD', 'DSFK', 'DGFK', 'JDSQ', 'XSHYBG'],
    variety: '学术推广',
    work: 'active'
  },
  {
    type: ['XSHYBG'],
    variety: '学术推广活动申请变更',
    work: 'activeModify'
  },
  {
    type: ['BILL_NON_MEETING_APP'],
    variety: '非学术项目',
    work: 'nonmeeting'
  },
  {
    type: ['YYBG'],
    variety: '医院变更',
    work: 'hosp'
  },
  {
    type: ['YSBG'],
    variety: '医生变更',
    work: 'doctor'
  },
  {
    type: ['JZBG'],
    variety: '讲者变更',
    work: 'speaker'
  },
  {
    type: ['MBYS'],
    variety: '维护次月目标医生',
    work: 'tgdoctor'
  },
  {
    type: ['MDSQ'],
    variety: '门店申请',
    work: 'store'
  },
  {
    type: ['BFBQ'],
    variety: '拜访补签',
    work: 'visit'
  },
  {
    type: ['QYWSJ'],
    variety: '区域外时间',
    work: 'outtime'
  },
  {
    type: ['LCOSTAPP'],
    variety: '地办费用',
    work: 'lcostapp'
  },
  {
    type: ['MBYYSQ'],
    variety: '目标医院',
    work: 'mbyysq'
  },
  {
    type: ['DIGIT_CONTRACT'],
    variety: '数字合同',
    work: 'contract'
  },
  {
    type: ['CLERK_MONTH_INV'],
    variety: '业务员月盘存',
    work: 'clerk'
  },
  {
    type: ['CLEAR_INV'],
    variety: '清算盘存',
    work: 'clear'
  },
  {
    type: ['BILL_BUSINESS_REFUND_CONTRACT'],
    variety: '非学术商业返款协议',
    work: 'business'
  },
  {
    type: ['PARTNER_AGREE_REFUND'],
    variety: '新零售销售费用',
    work: 'partner'
  },
  {
    type: ['NON_ACADEMIC_REFUND'],
    variety: '非学术协议返款',
    work: 'nonacademic'
  },
  {
    type: ['BILL_OUTER_COMP_BILL'],
    variety: '外部公司发货单',
    work: 'outercomp'
  },
  {
    type: ['BILL_GENERIC_DRUGS_DIGIT_CONTR'],
    variety: '仿制药数字合同',
    work: 'digit'
  },
  {
    type: ['ZFSQ'],
    variety: '药连锁办事处用房租赁申请',
    work: 'rent'
  },
  {
    type: ['ZFDJ'],
    variety: '药连锁自有房产登记申请',
    work: 'register'
  },
  {
    type: ['YGSSSQ'],
    variety: '药连锁公司驻地宿舍申请',
    work: 'dormitory'
  },
  {
    type: ['ZFDG'],
    variety: '药连锁对公付款单申请',
    work: 'zfdg'
  },
  {
    type: ['ZFDS'],
    variety: '药连锁对私付款单申请',
    work: 'zfds'
  },
  {
    type: ['FCBG'],
    variety: '药连锁房产续租/变更申请',
    work: 'fcbg'
  },
  {
    type: ['GFSJ'],
    variety: '丙肝公费送检申请单',
    work: 'hcvapp'
  },
  {
    type: ['GFHX'],
    variety: '丙肝公费送检核销单',
    work: 'hcvreim'
  },
  {
    type: ['WGSQ'],
    variety: '学术终端品种网格化申请',
    work: 'grid'
  },
  {
    type: ['CHS_DIGIT_CONTRACT'],
    variety: '学术数字合同',
    work: 'chsDigitContratct'
  }
]

const ACTIVE_COLUMNS = [
  {
    title: '待办标题',
    dataIndex: 'subject',
    width: 240,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'subjectItem'
    }
  },
  {
    title: '发起时间',
    dataIndex: 'receiveTime',
    width: 156,
    scopedSlots: { customRender: 'receiveTimeItem' }
  },
  {
    title: '单据类型',
    dataIndex: 'billtype',
    width: 140,
    scopedSlots: { customRender: 'billtypeItem' },
    filters: [
      { text: '学术推广活动申请单', value: 'XSSQ' },
      { text: '学术推广活动总结单', value: 'XSBX' },
      { text: '预付款单', value: 'YFKD' },
      { text: '对公付款单', value: 'DGFK' },
      { text: '对私付款单', value: 'DSFK' }
    ]
  },
  {
    title: '申请状态',
    dataIndex: 'state',
    width: 76,
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '下级审批人',
    dataIndex: 'curname',
    width: 114,
    scopedSlots: { customRender: 'sendnameItem' }
  }
]

const NOT_ACTIVE_COLUMNS = [
  {
    title: '待办标题',
    dataIndex: 'subject',
    width: 240,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'subjectItem'
    }
  },
  {
    title: '发起时间',
    dataIndex: 'receiveTime',
    width: 156,
    scopedSlots: { customRender: 'receiveTimeItem' }
  },
  {
    title: '单据类型',
    dataIndex: 'billtype',
    width: 140,
    scopedSlots: { customRender: 'billtypeItem' }
  },
  {
    title: '申请状态',
    dataIndex: 'state',
    width: 76,
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '下级审批人',
    dataIndex: 'curname',
    width: 114,
    scopedSlots: { customRender: 'sendnameItem' }
  }
]

export function getWorkConfig(work) {
  const billtypeList = BILL_VARIETY.find(item => item.work === work)
  if (!billtypeList) return []

  const list = cloneDeep(billtypeList.type)

  return list
}

export function getType(type, isUnbind) {
  switch (type) {
    case 'XSSQ':
      return '学术推广活动申请单'
    case 'XSHYBG':
      return '学术推广活动申请变更单'
    case 'XSBX':
      return '学术推广活动总结单'
    case 'BILL_NON_MEETING_APP':
      return '非会议类学术项目申请单'
    case 'DGFK':
      return '对公付款单'
    case 'DSFK':
      return '对私报销单'
    case 'YFKD':
      return '预付款单'
    case 'JDSQ':
      return '接待申请单'
    case 'YYBG':
      return '医院变更申请'
    case 'YSBG':
      return '医生变更申请'
    case 'JZBG':
      return '讲者变更申请'
    case 'MDSQ':
      return '门店申请'
    case 'BFBQ':
      return '拜访补签'
    case 'LCOSTAPP':
      return '省办费用申请'
    case 'MBYYSQ':
      return '目标医院申请'
    case 'DIGIT_CONTRACT':
      return '数字合同'
    case 'CHS_DIGIT_CONTRACT':
      return '学术数字合同'
    case 'CLERK_MONTH_INV':
      return '业务员月盘存'
    case 'CLEAR_INV':
      return '清算盘存'
    case 'PARTNER_AGREE_REFUND':
      return '新零售销售费用申请'
    case 'BILL_BUSINESS_REFUND_CONTRACT':
      return '非学术商业返款协议申请'
    case 'NON_ACADEMIC_REFUND':
      return '非学术协议返款申请'
    case 'BILL_OUTER_COMP_BILL':
      return '外部公司发货单申请'
    case 'BILL_GENERIC_DRUGS_DIGIT_CONTR':
      return '仿制药数字合同'
    case 'ZFSQ':
      return '药连锁办事处用房租赁申请'
    case 'ZFDJ':
      return '药连锁自有房产登记申请'
    case 'YGSSSQ':
      return '药连锁公司驻地宿舍申请'
    case 'ZFDG':
      return '药连锁对公付款单申请'
    case 'ZFDS':
      return '药连锁对私付款单申请'
    case 'FCBG':
      return '药连锁房产变更单申请'
    case 'GFSJ':
      return '丙肝公费送检申请单'
    case 'GFHX':
      return '丙肝公费送检核销单'
    case 'WGSQ':
      return '学术终端品种网格化申请单'
    case 'MBYS': {
      if (isUnbind) return '解绑申请'
      else return '常规申请'
    }
    case 'QYWSJ':
      return '区域外时间申请'
    default:
      return '无法识别单据类型'
  }
}

export { BILL_VARIETY, ACTIVE_COLUMNS, NOT_ACTIVE_COLUMNS }
