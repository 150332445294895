<template>
  <div class="p-4">
    <a-row>
      <template v-for="item in formBaseList">
        <a-col
          v-if="!item.show || item.show()"
          :xl="6"
          :xxl="4"
          :key="item.prop"
        >
          <a-form-item
            v-if="!item.show || item.show()"
            :label="item.label"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :required="
              item.required === undefined ? !disabledFn(item) : item.required
            "
          >
            <a-select
              v-if="item.type === 'select'"
              :value="formData[item.prop]"
              :disabled="disabledFn(item)"
              @change="onSelectChange($event, item)"
            >
              <a-select-option
                v-for="doc in item.options"
                :key="doc.value"
                :value="doc.value"
              >
                {{ doc.text }}
              </a-select-option>
            </a-select>
            <a-date-picker
              v-else-if="item.type === 'date'"
              class="w-full"
              format="YYYY-MM-DD"
              :value="formData[item.prop] ? moment(formData[item.prop]) : null"
              :allowClear="false"
              :disabledDate="
                item.disabledDate ? item.disabledDate() : () => false
              "
              :disabled="disabledFn(item)"
              @change="onDateChange(arguments, item)"
            />
            <a-input
              v-else-if="item.type === 'search'"
              v-model="formData[item.prop]"
              :disabled="disabledFn(item)"
              :title="formData[item.prop]"
              readOnly
            >
              <a-icon
                slot="suffix"
                type="search"
                @click="
                  () => {
                    if (disabledFn(item)) return
                    $emit('open-modal', item)
                  }
                "
              />
            </a-input>
            <a-input
              v-else-if="item.type === 'status'"
              :value="getStatusText(formData[item.prop])"
              :disabled="true"
              :title="getStatusText(formData[item.prop])"
            ></a-input>
            <a-input
              v-else
              :value="formData[item.prop]"
              :disabled="disabledFn(item)"
              :title="formData[item.prop]"
              :oninput="item.validate ? oninput[item.validate] : ''"
              @change="onInputChange($event, item)"
            ></a-input>
          </a-form-item>
        </a-col>
      </template>
    </a-row>

    <template v-if="formFileList && formFileList.length">
      <a-row>
        <template v-for="item in formFileList">
          <a-col
            v-if="!item.show || item.show()"
            :xl="6"
            :xxl="4"
            :key="item.prop"
          >
            <a-form-item v-if="!item.show || item.show()">
              <div class="flex items-center">
                <span class="relative">
                  <span :class="[item.notVerity ? '' : 'app-form-required']">
                    {{ item.label }} :
                  </span>
                  <span
                    class="absolute text-white cursor-pointer"
                    style="right: -30px"
                    @click="openFile(item)"
                  >
                    {{
                      formData[item.prop]
                        ? JSON.parse(formData[item.prop]).length
                        : '0'
                    }}
                  </span>
                </span>
                <icon-svg
                  icon="icon_wenjianjia3"
                  class="text-4xl ml-2 cursor-pointer"
                  @click="openFile(item)"
                />
              </div>
            </a-form-item>
          </a-col>
        </template>
      </a-row>
    </template>

    <template v-if="formTextAreaList && formTextAreaList.length">
      <a-row>
        <a-col :span="24" v-for="item in formTextAreaList" :key="item.prop">
          <a-form-item
            :label="item.label"
            :label-col="labelTextAreaCol"
            :wrapper-col="wrapperTextAreaCol"
            :required="
              item.required === undefined ? !disabledFn(item) : item.required
            "
          >
            <a-textarea
              :value="formData[item.prop]"
              placeholder="800字内"
              :disabled="disabledFn(item)"
              :maxLength="800"
              :autoSize="{ minRows: 1, maxRows: 6 }"
              @change="onInputChange($event, item)"
            ></a-textarea>
          </a-form-item>
        </a-col>
      </a-row>
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import { oninput } from '@/utils/validate'
import { getStatusText } from '@/configs/bill-msg'

export default {
  name: 'BaseFormItems',
  props: {
    // 数据
    formData: {
      type: Object,
      default: () => ({})
    },
    formBaseList: {
      type: Array,
      default: () => []
    },
    formFileList: {
      type: Array,
      default: () => []
    },
    formTextAreaList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      oninput,
      labelCol: { span: 20 },
      wrapperCol: { span: 20 },
      labelTextAreaCol: { span: 20 },
      wrapperTextAreaCol: { span: 24 }
    }
  },

  methods: {
    moment,
    getStatusText,

    onSelectChange(value, item) {
      this.$set(this.formData, item.prop, value)
      this.$emit('select-change', value, item)
    },

    onDateChange([date, dateString], item) {
      this.$set(this.formData, item.prop, dateString)
      this.$emit('date-change', [date, dateString], item)
    },

    onInputChange(e, item) {
      this.$set(this.formData, item.prop, e.target.value)
      this.$emit('input-change', e.target.value, item)
    },

    openFile(item) {
      this.$emit('open-file', item)
    },

    disabledFn(item) {
      return typeof item.disabled === 'boolean'
        ? item.disabled
        : item.disabled()
    }
  }
}
</script>

<style lang="less" scoped>
.app-form-required::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 12px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
</style>
