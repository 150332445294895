import request from '@/utils/request'

/**
 * 分页查询数字合同信息
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 * @returns
 */
export function getHeaderContractList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `chsDigitContract/page/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 根据表头id获取数字合同表体列表(分页)
 * @param {String} id
 * @param {Integer} pageindex
 * @param {Interger} pagenum
 * @param {Array} conditions
 * @returns
 */
export function getBodyContractList(id, pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `chsDigitContract/pageBody/${pageindex}/${pagenum}`,
    params: { hid: id },
    data: conditions
  })
}

/**
 * 删除数字合同
 * @param {String} id
 * @returns
 */
export function delContract(id) {
  return request({
    method: 'get',
    url: `chsDigitContract/delById`,
    params: { id }
  })
}

/**
 * 根据ID来查询数字合同信息
 * @param {String} id
 * @returns
 */
export function getContract(id) {
  return request({
    method: 'get',
    url: `chsDigitContract/${id}`
  })
}

/**
 * 保存/更新数字合同
 * @param {Object} data
 * @returns
 */
export function updateContract(data) {
  return request({
    method: 'post',
    url: `chsDigitContract/saveOrUpdate`,
    data
  })
}

/**
 * 提交数字合同
 * @param {Object} data
 * @returns
 */
export function submitContract(data) {
  return request({
    method: 'post',
    url: `chsDigitContract/submitChsDigitContract`,
    data
  })
}

/**
 * 取消审批
 * @param {String} id
 * @returns
 */
export function repealContract(id) {
  return request({
    method: 'get',
    url: `chsDigitContract/unApprove`,
    params: { id }
  })
}

export function updateEndDate(data) {
  return request({
    method: 'post',
    url: `chsDigitContract/rewriteEndDate`,
    data
  })
}
