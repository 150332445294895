const BASE = [
  {
    title: '基本类型',
    active: 'BASE',
    children: [
      { label: '单据号', value: `vbillno` },
      { label: '业务日期', value: `vbilldate` },
      { label: '业务员编码', value: 'clerkCode' },
      { label: '业务员', value: `clerkName` },
      { label: '业务区域', value: `chnlareaName` },
      { label: '开发者编码', value: 'developerCode' },
      { label: '开发者', value: `developerName` },
      { label: '变更原因', value: 'reasonName' },
      { label: '创建人', value: 'creatorName' },
      { label: '创建时间', value: 'createTime' },
      { label: '修改人', value: 'modifierName' },
      { label: '修改时间', value: 'modifyTime' }
    ]
  }
]
const TABLE = [
  {
    title: '信息列表',
    active: 'TABLE',
    dataList: 'bodys',
    columns: [
      { title: '行号', dataIndex: 'rowno', width: 48 },
      {
        title: '终端编码',
        dataIndex: 'hospitalCode',
        ellipsis: true,
        width: 120
      },
      {
        title: '终端名称',
        dataIndex: 'hospitalName',
        ellipsis: true,
        width: 120
      },
      {
        title: 'NC编码',
        dataIndex: 'ncCode',
        width: 78.33,
        ellipsis: true
      },
      {
        title: '大区',
        dataIndex: 'regionName',
        width: 78.33,
        ellipsis: true
      },
      {
        title: '省区',
        dataIndex: 'provinceName',
        width: 78.33,
        ellipsis: true
      },
      {
        title: '地办',
        dataIndex: 'localityName',
        width: 78.33,
        ellipsis: true
      },
      {
        title: '终端类型',
        dataIndex: 'chnlmemtypeStdName',
        width: 78.33,
        ellipsis: true
      },
      {
        title: '品种编码',
        dataIndex: 'drugFormCode',
        ellipsis: true,
        width: 120
      },
      {
        title: '品种名称',
        dataIndex: 'drugFormName',
        ellipsis: true,
        width: 120
      },
      {
        title: '规格',
        dataIndex: 'drugFormSpec',
        ellipsis: true,
        width: 120
      },
      { title: '开票价', dataIndex: 'billPrice', width: 72 },
      { title: '单盒费用', dataIndex: 'cost', width: 72 },
      { title: '二次议价', dataIndex: 'twicePrice', width: 72 },
      {
        title: '生效月份',
        dataIndex: 'startDate',
        ellipsis: true,
        width: 72
      },
      {
        title: '失效月份',
        dataIndex: 'endDate',
        ellipsis: true,
        width: 72
      },
      { title: '开发者开发奖金', dataIndex: 'developBonus', width: 96 },
      { title: '业务员开发奖金', dataIndex: 'clerkBonus', width: 96 },
      { title: '引进人奖金', dataIndex: 'importbonus', width: 96 }
    ]
  }
]

export { BASE, TABLE }
