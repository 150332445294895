import Vue from 'vue'
import {
  Button,
  Layout,
  Message,
  Icon,
  Menu,
  Modal,
  Table,
  Form,
  Input,
  Checkbox,
  Dropdown,
  Switch,
  Tabs,
  Tag,
  Transfer,
  Tree,
  TreeSelect,
  Row,
  Col,
  InputNumber,
  Radio,
  Card,
  Upload,
  ConfigProvider,
  Divider,
  Select,
  DatePicker,
  notification,
  Pagination,
  Progress,
  Popover,
  Badge,
  Spin,
  Anchor,
  Alert,
  Breadcrumb,
  Tooltip,
  Empty,
  Skeleton,
  BackTop,
  Collapse,
  Steps,
  List,
  Popconfirm,
  Avatar,
  PageHeader,
  Descriptions,
  Comment,
  Drawer,
  Carousel,
  AutoComplete,
  Cascader
} from 'ant-design-vue'

Vue.use(Layout)
Vue.use(Form)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Checkbox)
Vue.use(Radio)
Vue.use(Card)
Vue.use(Table)
Vue.use(Tabs)
Vue.use(Transfer)
Vue.use(Tag)
Vue.use(Icon)
Vue.use(Button)
Vue.use(Menu)
Vue.use(Modal)
Vue.use(Dropdown)
Vue.use(Switch)
Vue.use(Tree)
Vue.use(TreeSelect)
Vue.use(Row)
Vue.use(Col)
Vue.use(Upload)
Vue.use(ConfigProvider)
Vue.use(Divider)
Vue.use(Select)
Vue.use(DatePicker)
Vue.use(Progress)
Vue.use(Pagination)
Vue.use(Popover)
Vue.use(Badge)
Vue.use(Spin)
Vue.use(Anchor)
Vue.use(Alert)
Vue.use(Breadcrumb)
Vue.use(Tooltip)
Vue.use(Collapse)
Vue.use(Empty)
Vue.use(Skeleton)
Vue.use(BackTop)
Vue.use(Popconfirm)
Vue.use(Steps)
Vue.use(List)
Vue.use(Avatar)
Vue.use(PageHeader)
Vue.use(Descriptions)
Vue.use(Comment)
Vue.use(Drawer)
Vue.use(Carousel)
Vue.use(AutoComplete)
Vue.use(Cascader)

Message.config({
  duration: 2,
  maxCount: 2
})

/** 引入 IconFont */
const IconFont = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_1495234_xajr4n9rtyg.js'
})
Vue.component('icon-font', IconFont)

Vue.prototype.$message = Message
Vue.prototype.$notify = notification
Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$modal = Modal
