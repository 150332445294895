<template>
  <div>
    <xssq-bill
      v-if="showXSSQ"
      type="waitwork"
      :pid="pk"
      :appDate="appDate"
      @status="nowStatus($event)"
    ></xssq-bill>
    <xsbx-bill
      v-if="showXSBX"
      type="waitwork"
      :pid="pk"
      :appDate="appDate"
      @status="nowStatus($event)"
    ></xsbx-bill>
    <xshybg-bill
      v-if="showXSHYBG"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
    ></xshybg-bill>
    <dgfk-bill
      v-if="showDGFK"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
    ></dgfk-bill>
    <dsfk-bill
      v-if="showDSFK"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
    ></dsfk-bill>
    <yfkd-bill
      v-if="showYFKD"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
    ></yfkd-bill>
    <jdsq-bill
      v-if="showJDSQ"
      type="waitwork"
      ref="jdsq"
      :pid="pk"
      @status="nowStatus($event)"
    ></jdsq-bill>
    <ysbg-bill
      v-if="showYSBG"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
    ></ysbg-bill>
    <jzbg-bill
      v-if="showJZBG"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
    ></jzbg-bill>
    <yybg-bill
      v-if="showYYBG"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
    ></yybg-bill>
    <mbys-bill
      v-if="showMBYS"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
    ></mbys-bill>
    <mbyu-bill
      v-if="showMBYU"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
      @close="$emit('close')"
    ></mbyu-bill>
    <mdsq-bill
      v-if="showMDSQ"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
      @close="$emit('close')"
    ></mdsq-bill>
    <bfbq-bill
      v-if="showBFBQ"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
      @close="$emit('close')"
    ></bfbq-bill>
    <qywsj-bill
      v-if="showQYWSJ"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
      @close="$emit('close')"
    ></qywsj-bill>
    <lcostapp-bill
      v-if="showLCOSTAPP"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
      @close="$emit('close')"
    ></lcostapp-bill>
    <mbyysq-bill
      v-if="showMBYYSQ"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
      @close="$emit('close')"
    ></mbyysq-bill>
    <contract-Bill
      v-if="showCONTRACT"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
      @close="$emit('close')"
    ></contract-Bill>
    <clerk-bill
      v-if="showCLERK"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
      @close="$emit('close')"
    ></clerk-bill>
    <clear-bill
      v-if="showCLEAR"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
      @close="$emit('close')"
    ></clear-bill>
    <partner-bill
      v-if="showPARTNER"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
      @close="$emit('close')"
    ></partner-bill>
    <business-bill
      v-if="showBUSINESS"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
      @close="$emit('close')"
    ></business-bill>
    <nonacademic-bill
      v-if="showNONACADEMIC"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
      @close="$emit('close')"
    ></nonacademic-bill>
    <non-meeting-bill
      v-if="showNONMEETING"
      type="waitwork"
      :pid="pk"
      :summaryId="summaryId"
      @status="nowStatus($event)"
      @close="$emit('close')"
    ></non-meeting-bill>
    <outercomp-bill
      v-if="showOUTERCOMP"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
      @close="$emit('close')"
    ></outercomp-bill>

    <digit-Bill
      v-if="showDIGIT"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
      @close="$emit('close')"
    ></digit-Bill>

    <rent-house-bill
      v-if="showRentHouse"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
      @close="$emit('close')"
    ></rent-house-bill>

    <register-house-bill
      v-if="showRegistertHouse"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
      @close="$emit('close')"
    ></register-house-bill>
    <house-modify-bill
      v-if="showHouseModify"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
      @close="$emit('close')"
    ></house-modify-bill>
    <dormitory-bill
      v-if="showDormitoryHouse"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
      @close="$emit('close')"
    ></dormitory-bill>
    <house-dg-bill
      v-if="showHouseDg"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
      @close="$emit('close')"
    ></house-dg-bill>
    <house-ds-bill
      v-if="showHouseDs"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
      @close="$emit('close')"
    ></house-ds-bill>
    <gfsj-bill
      v-if="showHcvApp"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
      @close="$emit('close')"
    ></gfsj-bill>
    <gfhx-bill
      v-if="showHcvReim"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
      @close="$emit('close')"
    ></gfhx-bill>
    <grid-Bill
      v-if="showGrid"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
      @close="$emit('close')"
    ></grid-Bill>
    <chs-contract-bill
      v-if="showChsContract"
      type="waitwork"
      :pid="pk"
      @status="nowStatus($event)"
      @close="$emit('close')"
    ></chs-contract-bill>
  </div>
</template>

<script>
import XssqBill from '@/views/workplace/detailwaitworkmodal/audit-bill/XSSQBill'
import XsbxBill from '@/views/workplace/detailwaitworkmodal/audit-bill/XSBXBill'
import XshybgBill from '@/views/workplace/detailwaitworkmodal/audit-bill/XSHYBGBill'
import NonMeetingBill from '@/views/workplace/detailwaitworkmodal/audit-bill/NONMEETINGBill'
import DgfkBill from '@/views/workplace/detailwaitworkmodal/audit-bill/DGFKBill'
import DsfkBill from '@/views/workplace/detailwaitworkmodal/audit-bill/DSFKBill'
import YfkdBill from '@/views/workplace/detailwaitworkmodal/audit-bill/YFKDBill'
import JdsqBill from '@/views/workplace/detailwaitworkmodal/audit-bill/JDSQBill'
import YsbgBill from '@/views/workplace/detailwaitworkmodal/audit-bill/YSBGBill'
import YybgBill from '@/views/workplace/detailwaitworkmodal/audit-bill/YYBGBill'
import JzbgBill from '@/views/workplace/detailwaitworkmodal/audit-bill/JZBGBill'
import MbysBill from '@/views/workplace/detailwaitworkmodal/audit-bill/MBYSBill'
import MbyuBill from '@/views/workplace/detailwaitworkmodal/audit-bill/MBYUBill'
import MdsqBill from '@/views/workplace/detailwaitworkmodal/audit-bill/MDSQBill'
import BfbqBill from '@/views/workplace/detailwaitworkmodal/audit-bill/BFBQBill'
import QywsjBill from '@/views/workplace/detailwaitworkmodal/audit-bill/QYWSJBill'
import LcostappBill from '@/views/workplace/detailwaitworkmodal/audit-bill/LCOSTAPPBill'
import MbyysqBill from '@/views/workplace/detailwaitworkmodal/audit-bill/MBYYSQBill'
import ContractBill from '@/views/workplace/detailwaitworkmodal/audit-bill/CONTRACTBill'
import ClerkBill from '@/views/workplace/detailwaitworkmodal/audit-bill/CLERKBill'
import ClearBill from '@/views/workplace/detailwaitworkmodal/audit-bill/CLEARBill'
import PartnerBill from '@/views/workplace/detailwaitworkmodal/audit-bill/PARTNERBill'
import BusinessBill from '@/views/workplace/detailwaitworkmodal/audit-bill/BUSINESSBill'
import NonacademicBill from '@/views/workplace/detailwaitworkmodal/audit-bill/NONACADEMICBill'
import OutercompBill from '@/views/workplace/detailwaitworkmodal/audit-bill/OUTERCOMPBill'
import DigitBill from '@/views/workplace/detailwaitworkmodal/audit-bill/DIGITBill'
import RentHouseBill from '@/views/workplace/detailwaitworkmodal/audit-bill/RENTHOUSE'
import HouseModifyBill from '@/views/workplace/detailwaitworkmodal/audit-bill/HOUSEMODIFY'
import RegisterHouseBill from '@/views/workplace/detailwaitworkmodal/audit-bill/REGISTERHOUSE'
import DormitoryBill from '@/views/workplace/detailwaitworkmodal/audit-bill/DORMITORYHOUSE'
import HouseDgBill from '@/views/workplace/detailwaitworkmodal/audit-bill/DGHOUSE'
import HouseDsBill from '@/views/workplace/detailwaitworkmodal/audit-bill/DSHOUSE'
import GfsjBill from '@/views/workplace/detailwaitworkmodal/audit-bill/GFSJBill'
import GfhxBill from '@/views/workplace/detailwaitworkmodal/audit-bill/GFHXBill'
import GridBill from '@/views/workplace/detailwaitworkmodal/audit-bill/GRIDBill'
import ChsContractBill from '@/views/workplace/detailwaitworkmodal/audit-bill/CHSCONTRACTBill'

export default {
  name: 'ContentLeft',
  components: {
    XssqBill,
    XsbxBill,
    XshybgBill,
    DgfkBill,
    DsfkBill,
    YfkdBill,
    YsbgBill,
    JdsqBill,
    JzbgBill,
    YybgBill,
    MbysBill,
    MbyuBill,
    MdsqBill,
    BfbqBill,
    QywsjBill,
    LcostappBill,
    MbyysqBill,
    ContractBill,
    ClerkBill,
    ClearBill,
    PartnerBill,
    BusinessBill,
    NonacademicBill,
    NonMeetingBill,
    OutercompBill,
    DigitBill,
    RentHouseBill,
    HouseModifyBill,
    RegisterHouseBill,
    DormitoryBill,
    HouseDgBill,
    HouseDsBill,
    GfsjBill,
    GfhxBill,
    GridBill,
    ChsContractBill
  },
  props: {
    pk: String,
    billtype: String,
    appDate: String,
    unbindApp: Boolean,
    summaryId: String
  },
  data() {
    return {
      showXSSQ: false,
      showXSBX: false,
      showDGFK: false,
      showDSFK: false,
      showYFKD: false,
      showJDSQ: false,
      showYSBG: false,
      showYYBG: false,
      showJZBG: false,
      showMBYS: false,
      showMBYU: false,
      showMDSQ: false,
      showBFBQ: false,
      showQYWSJ: false,
      showLCOSTAPP: false,
      showMBYYSQ: false,
      showCONTRACT: false,
      showCLERK: false,
      showCLEAR: false,
      showPARTNER: false,
      showBUSINESS: false,
      showNONACADEMIC: false,
      showNONMEETING: false,
      showOUTERCOMP: false,
      showDIGIT: false,
      showRentHouse: false,
      showHouseModify: false,
      showRegistertHouse: false,
      showDormitoryHouse: false,
      showHouseDg: false,
      showHouseDs: false,
      showXSHYBG: false,
      showHcvApp: false,
      showHcvReim: false,
      showGrid: false,
      showChsContract: false
    }
  },
  created() {
    if (this.billtype === 'XSSQ') {
      this.showXSSQ = true
    }
    if (this.billtype === 'XSBX') {
      this.showXSBX = true
    }
    if (this.billtype === 'DGFK') {
      this.showDGFK = true
    }
    if (this.billtype === 'DSFK') {
      this.showDSFK = true
    }
    if (this.billtype === 'YFKD') {
      this.showYFKD = true
    }
    if (this.billtype === 'JDSQ') {
      this.showJDSQ = true
    }
    if (this.billtype === 'YSBG') {
      this.showYSBG = true
    }
    if (this.billtype === 'YYBG') {
      this.showYYBG = true
    }
    if (this.billtype === 'JZBG') {
      this.showJZBG = true
    }
    if (this.billtype === 'MDSQ') {
      this.showMDSQ = true
    }
    if (this.billtype === 'BFBQ') {
      this.showBFBQ = true
    }
    if (this.billtype === 'MBYS' && !this.unbindApp) {
      this.showMBYS = true
    }
    if (this.billtype === 'MBYS' && this.unbindApp) {
      this.showMBYU = true
    }
    if (this.billtype === 'QYWSJ') {
      this.showQYWSJ = true
    }
    if (this.billtype === 'LCOSTAPP') {
      this.showLCOSTAPP = true
    }
    if (this.billtype === 'MBYYSQ') {
      this.showMBYYSQ = true
    }
    if (this.billtype === 'DIGIT_CONTRACT') {
      this.showCONTRACT = true
    }
    if (this.billtype === 'CLERK_MONTH_INV') {
      this.showCLERK = true
    }
    if (this.billtype === 'CLEAR_INV') {
      this.showCLEAR = true
    }
    if (this.billtype === 'PARTNER_AGREE_REFUND') {
      this.showPARTNER = true
    }
    if (this.billtype === 'BILL_BUSINESS_REFUND_CONTRACT') {
      this.showBUSINESS = true
    }
    if (this.billtype === 'NON_ACADEMIC_REFUND') {
      this.showNONACADEMIC = true
    }
    if (this.billtype === 'BILL_NON_MEETING_APP') {
      this.showNONMEETING = true
    }
    if (this.billtype === 'BILL_OUTER_COMP_BILL') {
      this.showOUTERCOMP = true
    }
    if (this.billtype === 'BILL_GENERIC_DRUGS_DIGIT_CONTR') {
      this.showDIGIT = true
    }
    if (this.billtype === 'ZFSQ') {
      this.showRentHouse = true
    }
    if (this.billtype === 'FCBG') {
      this.showHouseModify = true
    }
    if (this.billtype === 'ZFDJ') {
      this.showRegistertHouse = true
    }
    if (this.billtype === 'YGSSSQ') {
      this.showDormitoryHouse = true
    }
    if (this.billtype === 'ZFDG') {
      this.showHouseDg = true
    }
    if (this.billtype === 'ZFDS') {
      this.showHouseDs = true
    }
    if (this.billtype === 'XSHYBG') {
      this.showXSHYBG = true
    }
    if (this.billtype === 'GFSJ') {
      this.showHcvApp = true
    }
    if (this.billtype === 'GFHX') {
      this.showHcvReim = true
    }
    if (this.billtype === 'WGSQ') {
      this.showGrid = true
    }
    if (this.billtype === 'CHS_DIGIT_CONTRACT') {
      this.showChsContract = true
    }
  },
  methods: {
    nowStatus(status) {
      this.$emit('status', status)
    },

    getJdsqData() {
      return this.$refs.jdsq.getData()
    }
  }
}
</script>

<style lang="less" scoped></style>
